import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BookmakerData, BooksObject, MarketData} from '../@types/response';
import {getBookmakers} from '../store/selectors/getBookmakers';
import {getMarkets} from '../store/selectors/getMarkets';
import {GetGroupBooksAction} from "../store/actions/GetGroupBooksAction";
import getBooks from "../store/selectors/monitor/getBooks";

const Group: React.FC = () => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const markets: MarketData = useSelector(getMarkets);
    const books: BooksObject[] = useSelector(getBooks);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetGroupBooksAction())
    }, []);

    return (
            <div className='max-w-screen-xl	mx-auto'>
                <h2 className='font-bold text-monitor-secondary py-4 text center'>MY GROUP BOOK</h2>
                <div className='flex'>
                    <div className='w-full'>
                        <div className='bg-white border-transparent rounded-lg shadow-md'>
                            <div
                                    className='bg-gray-200 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2'>
                                <h5 className='font-bold uppercase text-gray-600'>Odds</h5>
                            </div>
                            <div className='p-5'>
                                <div className='shadow overflow-hidden border-b border-gray-200'>
                                    <table className='min-w-full divide-y divide-gray-200'>
                                        <thead>
                                        <tr>
                                            <th>Book</th>
                                            <th>Market</th>
                                            <th>Sign</th>
                                            <th>Odd</th>
                                            <th>Time</th>
                                        </tr>
                                        </thead>
                                        <tbody className='bg-white divide-y divide-gray-200 text-center'>
                                        {books.map((book: BooksObject) => {
                                            const market = markets[book.marketId.toString()]
                                            return <tr>
                                                <td>{bookmakers[book.bookmakerId.toString()]?.name ?? ''}</td>
                                                <td>{market.name}</td>
                                                <td>{market.outcomes[book.signId.toString()].name}</td>
                                                <td>{book.odd}</td>
                                                <td>
                                                    {new Date(book.indt).toLocaleDateString()} {new Date(book.indt).toLocaleTimeString()}
                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default Group;
import {
    GET_HOT_MATCHES,
    GET_HOT_MATCHES_SUCCESS,
    OrderConstants,
    SET_SETTINGS
} from "../../constants/HotMatchConstants";
import {HotMatchObject} from "../../@types/response";
import {sortHotMatch} from "../utils/hotMatchUtils";

const initialState = {
    loading: false,
    hotMatches: [],
    settings: {
        bookmakers: [],
        date: 0,
        from: 1,
        to: 1,
        order: OrderConstants.DELTA,
        delta: 20,
        marketTv: 1000,
        sports: {}
    }
};

const hotMatchReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_SETTINGS: {
            const settings = Object.assign({}, state.settings, action.payload)
            return {
                ...state,
                settings: settings,
            }
        }
        case GET_HOT_MATCHES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_HOT_MATCHES_SUCCESS: {
            const hotMatches = action.payload.sort((a: HotMatchObject, b: HotMatchObject) => sortHotMatch(a, b, state.settings))
            return {
                ...state,
                hotMatches: hotMatches,
                loading: false
            };
        }
        default:
            return state;
    }
};

export default hotMatchReducer;

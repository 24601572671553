import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetInitialExtraCall} from "../../apis/user";
import {EXTRA_INITIALIZE_ERROR, EXTRA_INITIALIZE_SUCCESS} from "../../constants/ExtraConstants";

function* tryFunction() {
    const response: Record<string, string> = yield call(GetInitialExtraCall);
    yield put({
        type: EXTRA_INITIALIZE_SUCCESS,
        payload: response
    });
}

export function* GetBookedEventsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: EXTRA_INITIALIZE_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
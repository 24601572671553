import {getMoneyLine, PINNACLE} from "../../../constants/CommonConstants";
import {EventModalProps} from "../../../@types/inputs";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartArea, faChartLine, faSearch} from "@fortawesome/free-solid-svg-icons";
import {EventOddObject, MarketCapData, MarketCapObject, MoneyLineObject} from "../../../@types/response";
import {useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {ModalsContext} from "../modals/ModalsContext";
import {getBetfairUrl} from "../../../store/utils/events";
import {getMarketCap} from "../../../store/selectors/getMarketCap";
import {getEventsMoneyLine} from "../../../store/selectors/getEventsMoneyLine";
import Tooltip from "../common/Tooltip";
import {toMoney} from "../../../store/utils/commonUtils";

export default function DoClassicItem({data, reference}: { data: any; reference: number }) {
    const hasBetfair = "bMarketId" in data;
    const startDt = new Date(data.date);

    const marketCaps: MarketCapObject = useSelector(getMarketCap);
    const [marketCap, setMarketCap] = useState<MarketCapData>();
    const marketMoneyLines: MoneyLineObject = useSelector(getEventsMoneyLine);

    const [showSelectionChart, setShowSelectionChart] = useState<boolean>(false);
    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);

    useEffect(() => {
        setMarketCap(marketCaps[`${data.id}-${data.marketId}-${data.signId}`]);
    }, [marketCaps, data]);

    let best = "bg-monitor-margin-l2";
    if (data["margin-" + reference] > 15) {
        best = "bg-monitor-margin-l0";
    } else if (data["margin-" + reference] > 10) {
        best = "bg-monitor-margin-l1";
    }
    let bestLine = "border-monitor-margin-l2";
    if (data["margin-" + reference] > 15) {
        bestLine = "border-monitor-margin-l0";
    } else if (data["margin-" + reference] > 10) {
        bestLine = "border-monitor-margin-l1";
    }
    const label = `${data.category} - ${data.tournament}`;

    return (
            <tr className="border-b-2 tracking-[1px] border-black text-table-primary hover:bg-gray-600 bg-table-secondary">
                <td className="items-center 2xl:pl-20 pl-14 relative">
                    <div className={`border-r-[5px] absolute -left-2 2xl:mx-14 mx-8 2xl:h-12 h-9  ${bestLine}`}></div>
                    <Tooltip isEvent={true} tooltipText={`${data.name} ${label}`}>
                        <div className="flex flex-col py-1 2xl:max-w-[30rem] max-w-xs">
                            <div
                                    className="text-left text-white truncate 2xl:text-monitorBase text-[0.68rem] font-medium "
                                    data-id="event-name"
                                    data-value={data.name}
                            >
                                {data.name}
                            </div>

                            <div
                                    className="text-left 2xl:text-monitorBase truncate text-[0.68rem]"
                                    data-id="competition"
                                    data-value={label.substring(0, 32)}
                            >
                                {label.substring(0, 32)}
                            </div>
                        </div>
                    </Tooltip>
                </td>
                <td className="text-start">
                <span
                        className="cursor-pointer"
                        onClick={() => {
                            const _event: EventModalProps = {
                                id: data.id,
                                name: data.name,
                                sportId: data.sportId,
                            };
                            setModalsDataCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, {event: _event});
                            setOpenedModalsCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, true);
                        }}
                >
                    <FontAwesomeIcon icon={faSearch}/>
                </span>
                </td>

                <td
                        className=" py-1 text-center whitespace-nowrap"
                        data-id="event-time"
                        data-value={startDt.getTime()}
                >
                    <dt className="sr-only">Time</dt>
                    <dd>
                        {[
                            startDt.getDate().toString().padStart(2, "0"),
                            (startDt.getMonth() + 1).toString().padStart(2, "0"),
                        ].join("/")}
                        &nbsp;&nbsp;
                        {`${startDt.getHours().toString().padStart(2, "0")}:${startDt
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}
                    </dd>
                </td>
                <td
                        className={`py-1 px-2 text-xs whitespace-nowrap font-bold text-table-margin text-end ${best}`}
                >
                    {(data["margin-" + reference] ?? 0).toFixed(2)}
                </td>
                <td className="py-1 px-3 text-center whitespace-nowrap">
                    <p>{data.marketName}</p>
                </td>
                <td className="py-1 px-3 text-center whitespace-nowrap">{data.signName}</td>
                <td
                        className="py-1 px-3 text-center font-bold whitespace-nowrap cursor-pointer"
                        onClick={() => {
                            const betfairUrl = getBetfairUrl(data.bMarketId, data.bSelectionId);
                            betfairUrl && window.open(betfairUrl, "betfair", "width=925,height=660");
                        }}
                >
                    {data["odd"]}
                </td>
                <td className="py-1 px-3 whitespace-nowrap text-center line-through">
                    {reference === 0 ? data.opening : data["odd-" + reference]}
                </td>
                <td className="py-1 flex flex-row items-center px-3 text-center  whitespace-nowrap">
                    <div className="text-xs px-1">
                        {marketCap && <div> {toMoney(marketCap.m)}</div>}
                        {marketCap && <div> ({toMoney(marketCap.r)})</div>}
                    </div>
                    <span className={`${hasBetfair && "cursor-pointer"} px-1`}>
                    <FontAwesomeIcon
                            className={hasBetfair ? "" : "text-gray-400"}
                            icon={faChartArea}
                            onMouseEnter={() => {
                                hasBetfair && setShowSelectionChart(true);
                            }}
                            onMouseLeave={() => {
                                hasBetfair && setShowSelectionChart(false);
                            }}
                    />
                        {showSelectionChart && (
                                <img
                                        className="absolute"
                                        alt="MarketCap"
                                        style={{marginLeft: "-50%", zIndex: 9}}
                                        src={`https://xtsd.betfair.com/LoadRunnerInfoChartAction/?marketId=${
                                                data?.bMarketId ?? "0"
                                        }&selectionId=${data?.bSelectionId ?? 0}`}
                                />
                        )}
                    </span>
                    <span className="text-gray-400 cursor-pointer">
                        {getMoneyLine(data.marketId, marketMoneyLines[data.id.toString()] ?? {})}
                        <FontAwesomeIcon
                                icon={faChartLine}
                                className="ml-2"
                                onClick={() => {
                                    const odd: EventOddObject = {
                                        eventId: data.id,
                                        bookmakerId: PINNACLE,
                                        marketId: data.marketId,
                                        spread: data.spread,
                                        signId: data.signId,
                                    };
                                    const event = {id: data.id, name: data.name, sportId: data.sportId};
                                    setModalsDataCallback(MODALS_TYPES.SIGN_CHART_MODAL, {odd, event});
                                    setOpenedModalsCallback(MODALS_TYPES.SIGN_CHART_MODAL, true);
                                }}
                        />
                    </span>
                </td>
            </tr>
    );
}

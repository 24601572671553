import {takeLatest} from 'redux-saga/effects';
/*CONSTANTS*/
import {
    BOOK_EVENT,
    DELETE_ALERT,
    DELETE_EXTRA_SETTINGS,
    GET_EVENT,
    GET_EVENTS,
    GET_EXTRA_EVENTS,
    GET_EXTRA_ODDS_EVENTS,
    GET_EXTRA_SETTINGS,
    HIDE_ALERT,
    EXTRA_INITIALIZE,
    SEARCH_EVENT,
    STORE_EXTRA_INITIALIZE
} from '../../constants/ExtraConstants';
/*SAGAS*/
import {getExtraEventsSaga} from './getExtraEventsSaga';
import {getExtraOddsEventsSaga} from './getExtraOddsEventsSaga';
import {getEventSaga} from "./getEventSaga";
import {GetBookedEventsSaga} from "./getBookedEvenstsSaga";
import {bookEventSaga} from "./bookEventSaga";
import {searchEventSaga} from "./searchEventSaga";
import {storeSettingSaga} from "./storeSettingSaga";
import {hideAlertSaga} from "./hideAlert";
import {getSettingSaga} from "./getSettingSaga";
import {deleteSettingSaga} from "./deleteSettingSaga";
import {getEventsSaga} from './getEventsSaga';
import {deleteAlertSaga} from './deleteAlert';

export function* StoreSetting(): Generator {
    // @ts-ignore
    yield takeLatest(STORE_EXTRA_INITIALIZE, storeSettingSaga);
}

export function* GetSetting(): Generator {
    // @ts-ignore
    yield takeLatest(GET_EXTRA_SETTINGS, getSettingSaga);
}

export function* DeleteSetting(): Generator {
    // @ts-ignore
    yield takeLatest(DELETE_EXTRA_SETTINGS, deleteSettingSaga);
}

export function* GetBookedEvents(): Generator {
    // @ts-ignore
    yield takeLatest(EXTRA_INITIALIZE, GetBookedEventsSaga);
}

export function* GetMonitorEvents(): Generator {
    yield takeLatest(GET_EXTRA_EVENTS, getExtraEventsSaga);
}

export function* GetMonitorOddsEvents(): Generator {
    yield takeLatest(GET_EXTRA_ODDS_EVENTS, getExtraOddsEventsSaga);
}

export function* GetEvent(): Generator {
    // @ts-ignore
    yield takeLatest(GET_EVENT, getEventSaga);
}

export function* BookEvent(): Generator {
    // @ts-ignore
    yield takeLatest(BOOK_EVENT, bookEventSaga);
}

export function* DeleteAlert(): Generator {
    // @ts-ignore
    yield takeLatest(DELETE_ALERT, deleteAlertSaga);
}

export function* SearchEvent(): Generator {
    // @ts-ignore
    yield takeLatest(SEARCH_EVENT, searchEventSaga);
}

export function* HideAlert(): Generator {
    // @ts-ignore
    yield takeLatest(HIDE_ALERT, hideAlertSaga);
}

export function* GetEvents(): Generator {
    // @ts-ignore
    yield takeLatest(GET_EVENTS, getEventsSaga);
}



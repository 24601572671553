import {skeletonGet} from './skeleton/skeletonGet';
import {GetOddHistoryPayload, SearchEventPayload} from "../@types/actions";

export const GetEventCall = ({eventId}: { eventId: number }): Promise<any> => {
    return skeletonGet(`${eventId}/main`, 'event');
};

export const SearchEventCall = (payload: SearchEventPayload): Promise<any> => {
    return skeletonGet(payload.event, 'event/search');
};

export const GetOddHistoryCall = (payload: GetOddHistoryPayload): Promise<any> => {
    const query = `b=${payload.bookmakerId}&m=${payload.marketId}&s=${payload.signId}${!payload.spread || payload.spread === '#' ? '' : `&p=${payload.spread}`}`
    return skeletonGet(`${payload.eventId}?${query}`, 'event/odd-history');
};

export const GetEventsRawCall = (): Promise<any> => {
    return skeletonGet('', 'event');
};
import {FC} from "react";

interface FilterInputProps {
    id: string
    setValue: any
    value: string
    placeholder: string
    small?: boolean
}

const FilterNameInput: FC<FilterInputProps> = ({
                                                   id,
                                                   placeholder,
                                                   value,
                                                   setValue,
                                                   small = false
                                               }) => {
    return <div
            className={`relative flex flex-col ${small ? 'mr-1 lg:text-monitorBase' : 'mr-2 2xl:text-monitorBase'} text-monitorSm`}>

        <input
                id={id}
                type="text"
                className={`${small ? 'w-20 mt-4' : '2xl:text-monitorBase'} text-monitorSm text-white bg-[#272C34] focus:outline-none rounded-xl p-1`}
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
        />

    </div>
}

export default FilterNameInput
import {SetOrderAction} from '../../../store/actions/hotmatch/SetOrderAction';
import {useDispatch, useSelector} from 'react-redux';
import {HotMatchSettingsObject} from '../../../@types/response';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSortDown} from '@fortawesome/free-solid-svg-icons'
import {OrderConstants} from "../../../constants/HotMatchConstants";
import {getHotMatchesLength} from "../../../store/selectors/hotmatch/getHotMatchesLength";
import {getSettings} from "../../../store/selectors/hotmatch/getSettings";


export default function HotMatchesTable({children}: { children?: any }) {

    const settings: HotMatchSettingsObject = useSelector(getSettings);
    const showedAlerts: number = useSelector(getHotMatchesLength);
    const dispatch = useDispatch();

    return (
            <div className='w-full inline-block'>
                <table className='text-table-primary w-full table-fixed'>
                    <colgroup>
                        {/*event_name*/}
                        <col className='w-auto'/>
                        {/*search*/}
                        <col className='w-8'/>
                        {/*date*/}
                        <col className='w-[10%]'/>
                        {/*delta*/}
                        <col className='w-16'/>
                        {/*market*/}
                        <col className='w-[10%]'/>
                        {/*sign*/}
                        <col className='w-[10%]'/>
                        {/*odd*/}
                        <col className='w-[10%]'/>
                        {/*betfair cap*/}
                        <col className='w-[10%]'/>
                        {/*actions*/}
                        <col className='w-[4%]'/>
                    </colgroup>
                    <thead>
                    <tr className='uppercase 2xl:text-monitorBase text-monitorSm tracking-[-0.11px] items-center leading-normal'>
                        <th className='text-left pl-16 py-1'>
                            Event/Competition
                        </th>
                        <th/>
                        <th className='py-1 text-center cursor-pointer relative'
                            onClick={() => {
                                settings.order !== OrderConstants.DATE && dispatch(SetOrderAction(OrderConstants.DATE))
                            }}>Date {settings.order === OrderConstants.DATE &&
                                <div className='absolute 2xl:right-12 right-8 top-0'>
                                    <FontAwesomeIcon icon={faSortDown}/>
                                </div>
                        }
                        </th>
                        <th className='py-1 text-end cursor-pointer relative' onClick={() => {
                            settings.order !== OrderConstants.DELTA && dispatch(SetOrderAction(OrderConstants.DELTA))
                        }}>Delta {settings.order === OrderConstants.DELTA &&
                                <div className='absolute 2xl:right-12 right-8 top-0'>
                                    <FontAwesomeIcon icon={faSortDown}/>
                                </div>
                        }
                        </th>
                        <th className='py-1 px-3 text-center'>Market</th>
                        <th className='py-1 px-3 text-center'>Sign</th>
                        <th className='py-1 text-center cursor-pointer relative' onClick={() => {
                            settings.order !== OrderConstants.ODD && dispatch(SetOrderAction(OrderConstants.ODD))
                        }}>Odd {settings.order === OrderConstants.ODD &&
                                <div className='absolute 2xl:right-12 right-8 top-0'>
                                    <FontAwesomeIcon icon={faSortDown}/>
                                </div>
                        }
                        </th>
                        <th colSpan={2}>
                            <label className='float-left py-1 mr-4'>TOT</label>
                            <p className='2xl:text-base px-2 text-monitorSm text-black float-left bg-white'>{showedAlerts}</p>
                        </th>
                    </tr>
                    </thead>
                </table>
                <div style={{
                    background: "url('img/table_background.jpg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
                     className='2xl:monitor-table-wrapper border-2 border-hotmatch-header bg-table-hotmatch monitor-table-wrapper-1440 overflow-auto dropdown-hidden-scrollbar relative rounded-[40px] mb-4'>
                    <table className='text-table-primary w-full table-fixed'>
                        <colgroup>
                            {/*event_name*/}
                            <col className='w-auto'/>
                            {/*search*/}
                            <col className='w-8'/>
                            {/*date*/}
                            <col className='w-[10%]'/>
                            {/*delta*/}
                            <col className='w-16'/>
                            {/*market*/}
                            <col className='w-[10%]'/>
                            {/*sign*/}
                            <col className='w-[10%]'/>
                            {/*odd*/}
                            <col className='w-[10%]'/>
                            {/*betfair cap*/}
                            <col className='w-[10%]'/>
                            {/*actions*/}
                            <col className='w-[4%]'/>
                        </colgroup>
                        <tbody className='2xl:text-monitorBase text-monitorSm font-normal'>
                        {children}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

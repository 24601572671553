import React, {useEffect, useState} from "react";
import {SettingsObject, SuggestionObject} from "../../../@types/response";
import {useDispatch, useSelector} from "react-redux";
import FilterInput from "../common/FilterInput";
import getErrorMessage from "../../../store/selectors/getErrorMessage";
import {Autocomplete} from "../common/Autocomplete";
import {getSuggestions} from "../../../store/selectors/monitor/getSuggestions";
import Logo from "../logo";
import Separator from "../Separator";
import {getSettings} from "../../../store/selectors/monitor/getSettings";
import {SearchEventAction} from "../../../store/actions/monitor/SearchEventAction";
import {ToolBarInstantViewProps} from "../../../@types/inputs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolumeHigh, faVolumeXmark} from "@fortawesome/free-solid-svg-icons";
import {HeartBeatDropDown} from "../common/HeartBeatDropDown";

export default function DoInstantToolbar({
                                             beep,
                                             setBeep,
                                             from,
                                             setFrom,
                                             to,
                                             setTo,
                                             setDate,
                                         }: ToolBarInstantViewProps) {

    const suggestions: SuggestionObject[] = useSelector(getSuggestions);
    const errorMessage: string = useSelector(getErrorMessage);
    const storedSettings: SettingsObject = useSelector(getSettings);
    const dispatch = useDispatch();

    const [, setPendignSettings] = useState<boolean>(false);
    const [settings, setSetting] = useState<SettingsObject>(storedSettings);

    useEffect(() => {
        setSetting(storedSettings);
    }, [storedSettings]);

    useEffect(() => {
        setPendignSettings(JSON.stringify(settings) !== JSON.stringify(storedSettings));
    }, [settings, storedSettings]);

    return (
            <div className="flex w-full h-14 2xl:text-monitorBase text-monitorSm">
                <Logo/>
                <ul className="flex  py-2 w-full">
                    <Separator/>
                    <li className="mx-4  flex items-center ">
                        <Autocomplete
                                placeholder="search event"
                                searchAction={(value) => dispatch(SearchEventAction(value))}
                                suggestions={suggestions}
                        />
                    </li>

                    <Separator/>

                    <li className="mx-4">
                        <div className="relative flex flex-col ml-4">
                            <label htmlFor="dataFilter" className={"text-white"}>
                                EVENT
                            </label>
                            <select
                                    id="dataFilter"
                                    name="dataFilter"
                                    className={"text-white bg-toolbar-bgMain"}
                                    onChange={({target}) => {
                                        setSetting(Object.assign({}, settings, {date: parseInt(target.value)}));
                                        setDate(parseInt(target.value));
                                    }}
                                    value={settings.date}
                            >
                                <option value={0}>All</option>
                                <option value={1}>Today</option>
                                <option value={2}>Today + 1</option>
                                <option value={3}>Today + 2</option>
                                <option value={4}>Today + 3</option>
                                <option value={5}>Today + 4</option>
                                <option value={6}>Today + 5</option>
                                <option value={7}>Today + 6</option>
                            </select>
                        </div>
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="from"
                                name="from"
                                label="FROM"
                                min={1}
                                max={4}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFrom(parseFloat(e.target.value));
                                }}
                                value={from}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4 ">
                        <FilterInput
                                id="to"
                                name="to"
                                label="TO"
                                min={0}
                                max={50}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setTo(parseFloat(e.target.value));
                                }}
                                value={to}
                        />
                    </li>

                    <Separator/>

                    <div
                            onClick={() => setBeep(!beep)}
                            className={`cursor-pointer flex items-center mx-4 ${beep ? "mr-[15px]" : "mr-[17px]"} `}
                    >
                        {beep ? (
                                <FontAwesomeIcon icon={faVolumeHigh} color="white" size="lg"/>
                        ) : (
                                <FontAwesomeIcon icon={faVolumeXmark} color="white" size="lg"/>
                        )}
                    </div>
                    <Separator/>
                    <div className="w-full flex justify-end items-center">
                        <li className="mr-4">
                            <HeartBeatDropDown/>
                        </li>
                    </div>
                </ul>
                <div>{errorMessage}</div>
            </div>
    );
}

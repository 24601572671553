import {FC, Fragment, useEffect, useMemo, useState} from "react";
import {BookmakerData, PlayerMarketObject} from "../../../@types/response";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {BlacklistPlayerAction} from "../../../store/actions/player/BlacklistPlayerAction";
import {ENABLED_BOOKMAKERS} from "../../../constants/PlayerConstants";
import {AVERAGE, BETFAIR} from "../../../constants/CommonConstants";
import {getBetfairUrl} from "../../../store/utils/events";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {getMainSbv} from "../../../store/utils/playerUtils";

interface PlayerTableProps {
    eventId: number;
    playerId: string;
    odds: any;
    player: { id: string, name: string, team: string, maps: number, averages: any };
    markets: PlayerMarketObject[]
}

export const PlayerTable: FC<PlayerTableProps> = ({odds, eventId, playerId, player, markets}) => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const [sbvs, setSbvs]: any = useState({})

    const [bestBookmakers, setBestBookmakers]: any = useState({});

    const dispatch = useDispatch();

    const playerBookmakers: BookmakerData = useMemo(() => {
        return Object.keys(bookmakers)
                .filter(id => ENABLED_BOOKMAKERS.has(parseInt(id)))
                .reduce((a, v) => ({...a, [v]: bookmakers[v]}), {})
    }, [bookmakers])

    const eventBookmakers: number[] = Object.values(playerBookmakers).map(b => b.id);

    useEffect(() => {
        if (!markets.length || !Object.keys(odds).length) {
            setBestBookmakers({})
            return
        }
        let marketsSbvs: any = {}
        for (const market of markets) {
            marketsSbvs[market.name] = getMainSbv(odds, market.id)
        }
        setSbvs(marketsSbvs)
        let bests: any = {}
        for (const market of markets) {
            for (const bookmakerId in bookmakers) {
                if (bookmakers[bookmakerId].isComparison || bookmakers[bookmakerId].details < 0) continue
                if (!bests[market.id.toString()]) bests[market.id.toString()] = {}
                for (const sign of Object.keys(market.outcomes)) {
                    const sbv = marketsSbvs[market.name]?.[playerId.toString()]?.[bookmakerId] ? marketsSbvs[market.name][playerId.toString()][bookmakerId] : '#'
                    const backOdd = odds[bookmakerId]?.players[playerId]?.['markets']?.[market.id]?.[sbv]?.[sign]?.odd || 0
                    if (!bests[market.id.toString()][sbv]) bests[market.id.toString()][sbv] = {}
                    if (!bests[market.id.toString()][sbv][sign]) {
                        bests[market.id.toString()][sbv][sign] = backOdd
                    } else {
                        bests[market.id.toString()][sbv][sign] = backOdd > bests[market.id.toString()][sbv][sign] ? backOdd : bests[market.id.toString()][sbv][sign]
                    }
                }
            }
        }
        setBestBookmakers(bests)
    }, [markets, odds])

    return <table className='mt-4 border-spacing-0 table-fixed w-full'>
        <thead>
        <tr>
            <th style={{width: '125px'}} className='text-left sticky left-0 z-10 bg-eventDetail-primary'/>
            <th style={{width: '125px'}} className={'font-extralight text-sm text-center px-2 whitespace-nowrap'}/>
            <th style={{width: '5px'}} className='bg-eventDetail-primary'></th>
            {markets.map(item => {
                return <Fragment key={item.id}>
                    {Object.keys(item.outcomes).map(sign => {
                        return <Fragment>
                            <th title={item.outcomes[sign].name} style={{width: '50px'}}
                                className={'font-extralight text-sm text-center px-1 overflow-hidden'}>
                                <span style={{fontSize: 'smaller'}}>{item.name}</span> <br/>
                                {item.outcomes[sign].name}
                            </th>
                            <th style={{width: '5px'}}/>
                        </Fragment>
                    })
                    }
                </Fragment>
            })}
        </tr>
        </thead>
        <tbody>
        {eventBookmakers.map((order_id) => {
            if (order_id === undefined || !Object.keys(bookmakers).includes(order_id.toString())) return null
            const bookmaker = bookmakers[order_id]
            const bgBook = 'bg-eventDetail-bookmaker'
            return <tr key={`book-${bookmaker.id}`}
                       className={`${bookmaker.details > 0 ? 'border' : 'border-b'} border-monitor-primary ${bgBook}`}>
                <th style={{height: '32px', width: '125px'}}
                    className={`text-left sticky left-0 z-10 ${bgBook} border-eventDetail-primary`}>
                    {bookmaker.name}
                </th>
                <th style={{width: '125px'}}
                    title={bookmaker.id !== AVERAGE ? odds[bookmaker.id]?.players[playerId.toString()]?.name : player?.name}
                    className='text-left pl-2 pr-4 relative btn-on-over whitespace-nowrap overflow-hidden'>
                    <div
                            className='absolute right-1 top-0 cursor-pointer text-red-500 btn-remove'
                            onClick={() => dispatch(BlacklistPlayerAction({
                                eventId,
                                playerId,
                                bookmakerId: order_id
                            }))}
                    >
                        <FontAwesomeIcon
                                icon={faTimes}
                        />
                    </div>
                    {bookmaker.id !== AVERAGE ? odds[bookmaker.id]?.players[playerId.toString()]?.name : player?.name}
                </th>
                <th style={{width: '5px'}} className='bg-eventDetail-primary'></th>
                {markets.map(item => {
                    return (
                            <Fragment key={item.id}>
                                {Object.keys(item.outcomes).map(sign => {
                                    try {
                                        const sbv = sbvs[item.name][playerId.toString()] ? sbvs[item.name][playerId.toString()][bookmaker.id] : '#';
                                        const backOdd = bookmaker.id === AVERAGE && !item.sbv ? player?.averages[item?.id.toString()]?.["#"]?.[sign]?.odd : odds[bookmaker.id]?.players[playerId.toString()]?.markets[item?.id.toString()]?.[sbv]?.[sign]?.odd;
                                        const betfairUrl = getBetfairUrl(
                                                odds[BETFAIR]?.players[playerId.toString()]?.markets[item?.id.toString()]?.[sbv]?.[sign]?.bMarketId,
                                                odds[BETFAIR]?.players[playerId.toString()]?.markets[item?.id.toString()]?.[sbv]?.[sign]?.bSelectionId
                                        );
                                        const top = bestBookmakers[item.id.toString()]?.[sbv]?.[sign] ?? 0;
                                        return (
                                                <Fragment key={sign}>
                                                    <td style={{width: '50px'}}
                                                        onClick={bookmaker.id === BETFAIR ? () => {
                                                            betfairUrl && window.open(betfairUrl, "betfair", "width=925,height=660");
                                                        } : () => {
                                                        }}
                                                        className={`text-center px-1.5 ${!bookmaker.isComparison && backOdd >= top && 'outline outline-1 -outline-offset-2 outline-eventDetail-secondary bg-eventDetail-top'} ${bookmaker.id === BETFAIR ? 'cursor-pointer' : ''}`}>
                                                        <b>{backOdd > 1 ? sbv !== '#' ? backOdd + ` (${sbv})` : backOdd : '-'}</b>
                                                    </td>
                                                    <td style={{width: '5px'}} className='bg-eventDetail-primary'/>
                                                </Fragment>
                                        );
                                    } catch (ex) {
                                        return null;
                                    }
                                })}
                            </Fragment>
                    );
                })}
            </tr>
        })}
        </tbody>
    </table>
}

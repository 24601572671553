import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';
import {PlayerTable} from "../player/PlayerTable";
import {EventStoredObject, PlayerBookmakersStoredObject, PlayerMarketObject} from '../../../@types/response';
import {getPlayers} from "../../../store/utils/playerUtils";
import {useDispatch, useSelector} from 'react-redux';
import {GetEventPlayersAction} from '../../../store/actions/player/GetEventPlayersAction';
import {GetEventPlayers} from '../../../store/selectors/player/GetEventPlayers';
import {ClearEventPlayersAction} from '../../../store/actions/player/ClearEventPlayersAction';
import {getPlayerMarketsList} from "../../../store/selectors/getPlayerMarketsList";
import {Dropdown, DropDownMultipleItem} from "../common/DropDown";
import {PLAYER_DETAILS_MARKETS, PLAYER_MARKETS} from "../../../constants/CommonConstants";
import {getSettings} from "../../../store/selectors/player/getSettings";
import {formatTimestamp} from "../../../store/utils/commonUtils";
import {GetEventCall} from "../../../apis/event";

const PlayerDetailsModal: React.FC = () => {
    const markets: PlayerMarketObject[] = useSelector(getPlayerMarketsList);
    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);
    const opening = openedModals.indexOf(MODALS_TYPES.PLAYER_DETAILS_MODAL) !== -1
    const onCancelClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, false);
        dispatch(ClearEventPlayersAction())
    }, [setOpenedModalsCallback]);

    const dispatch = useDispatch();

    const averageBookmakers: number[] = useSelector(getSettings).comparisons[0]?.bookmakers || []
    const event: EventStoredObject = (modalsData[MODALS_TYPES.PLAYER_DETAILS_MODAL] || {}).event;
    const selectedId = (modalsData[MODALS_TYPES.PLAYER_DETAILS_MODAL] || {}).playerId;
    const marketId = (modalsData[MODALS_TYPES.PLAYER_DETAILS_MODAL] || {}).marketId || null;

    const eventPlayers: null | PlayerBookmakersStoredObject = useSelector(GetEventPlayers);

    const [books, setBooks] = useState<object>({});
    const [players, setPlayers] = useState<any[]>([]);
    const [playerId, setPlayerId] = useState<string>('');
    const [eventDetail, setEventDetail] = useState<any>(null);

    const [selectedMarkets, setSelectedMarkets] = useState<PlayerMarketObject[]>(markets)
    const [selectedMarketGroups, setSelectedMarketGroups] = useState<string[]>([])

    const escFunction = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setOpenedModalsCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, false);
        }
    }, []);

    useEffect(() => {
        if (!eventPlayers) {
            setPlayers([])
            setBooks({})
        } else {
            setPlayers(Object.values(getPlayers(eventPlayers ?? {}, averageBookmakers))
                    .sort((a, b) => `${a.team}${a.name}` > `${b.team}${b.name}` ? 1 : -1))
            setBooks(eventPlayers)
        }
    }, [eventPlayers])

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        setPlayerId(selectedId)
    }, [selectedId])

    useEffect(() => {
        if (!opening || !event._id) return;
        GetEventCall({eventId: event._id}).then((result) => {
            setEventDetail(result)
            setSelectedMarketGroups(marketId ? [marketId] : result.sportId === 2 ? ['Points'] : ['Goalscorers'])
        })
        dispatch(GetEventPlayersAction(event._id))
    }, [opening])

    useEffect(() => {
        let newMarkets: PlayerMarketObject[] = []
        selectedMarketGroups.forEach(g => {
            Object.values(PLAYER_MARKETS).forEach(m => {
                if (m.id === g) {
                    newMarkets.push(m)
                }
            })
        })
        setSelectedMarkets(newMarkets);
    }, [selectedMarketGroups]);

    return (
            (openedModals.indexOf(MODALS_TYPES.PLAYER_DETAILS_MODAL) > -1 && (
                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div className='flex items-center justify-center min-h-screen text-center relative'>
                            <div className='fixed inset-0 bg-modal-layer transition-opacity'/>
                            <div style={{width: '700px'}}
                                 className='bg-eventDetail-primary rounded-lg text-left shadow-xl transform transition-all border-solid border-eventDetail-secondary border-2'>
                                <button
                                        type='button'
                                        className='hover:bg-blue-600 hover:text-black hover:border-black absolute z-20 bg-eventDetail-primary -right-4 -top-4 text-eventDetail-event border border-eventDetail-event rounded-full h-8 w-8'
                                        onClick={onCancelClickCb}
                                >
                                    x
                                </button>
                                <div className='text-white px-4 py-2'>
                                    <div className='mt-2'>
                                        <div className='flex'>
                                            <div style={{
                                                background: `url('${process.env.PUBLIC_URL}/static/img/logo_icon.svg')`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                            }}
                                                 className='w-[60px] h-[60px] mb-2.5'></div>
                                            <div className='flex flex-col justify-end mb-2.5 ml-2.5'>
                                                <h2>{event.name.substring(0, 40)}<span
                                                        className='absolute right-4'>ID: {event._id}</span></h2>
                                                <h2 className='text-eventDetail-event font-medium'>
                                                    {eventDetail ? (eventDetail.category || '').substring(0, 15) + ' - ' +
                                                            eventDetail.tournament + ' | '
                                                            + formatTimestamp(eventDetail.date, true)
                                                            : ''}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='flex flex-col justify-end mb-2.5 ml-2.5'>
                                                <select
                                                        className='text-black'
                                                        onChange={(e) => setPlayerId(e.target.value)}
                                                        value={playerId}
                                                >
                                                    {players.map((p) => <option
                                                            key={p.id}
                                                            value={p.id}
                                                    >{p.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='flex border-b'>
                                            <div className='flex flex-col justify-end mb-2.5 ml-2.5'>
                                                <Dropdown label='MARKETS' color='default'>
                                                    {eventDetail && PLAYER_DETAILS_MARKETS[eventDetail.sportId]
                                                            .map((marketGroup) => {
                                                                return (
                                                                        <DropDownMultipleItem
                                                                                key={marketGroup}
                                                                                label={marketGroup}
                                                                                selected={selectedMarketGroups.includes(marketGroup)}
                                                                                onClick={() => {
                                                                                    if (selectedMarketGroups.includes(marketGroup)) {
                                                                                        if (selectedMarketGroups.length > 1) {
                                                                                            setSelectedMarketGroups(selectedMarketGroups.filter(g => g !== marketGroup))
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedMarketGroups([marketGroup])
                                                                                    }
                                                                                }}
                                                                                onButtonClick={() => {
                                                                                    if (selectedMarketGroups.includes(marketGroup)) {
                                                                                        if (selectedMarketGroups.length > 1) {
                                                                                            setSelectedMarketGroups(selectedMarketGroups.filter(g => g !== marketGroup))
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedMarketGroups(selectedMarketGroups.concat([marketGroup]))
                                                                                    }
                                                                                }}
                                                                        />
                                                                );
                                                            })}
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                                className='w-full overflow-x-scroll overflow-y-visible h-[520px] dropdown-hidden-scrollbar'
                                        >
                                            <PlayerTable
                                                    eventId={event._id}
                                                    playerId={playerId}
                                                    odds={books}
                                                    player={players.find(p => p.id === playerId)}
                                                    markets={selectedMarkets}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )) ||
            null
    )
};
export default PlayerDetailsModal;

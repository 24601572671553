import {call, put} from 'redux-saga/effects';
import {LOGIN_FAILED, LOGIN_SUCCESS} from '../../constants/LoginConstants';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {LoginPayload} from '../../@types/actions';
import {GetBookmakersCall, LoginCall} from "../../apis/user";
import {BookmakerData, LoginResponse, MarketCapObject, MoneyLineObject} from "../../@types/response";
import {GET_EVENT_MONEY_LINE_SUCCESS, GET_MARKET_CAP_SUCCESS, SET_BOOKMAKERS} from "../../constants/CommonConstants";
import {GetEventsMoneyLineCall, GetMarketCapCall} from "../../apis/monitor";
import {ENABLED_BOOKMAKERS, SET_SETTINGS_AVERAGE_BOOKMAKERS} from "../../constants/PlayerConstants";

function* tryFunction(payload: LoginPayload) {
    const response: LoginResponse = yield call(LoginCall, payload);

    localStorage.setItem('sessionToken', response.jwt);

    yield put({
        type: LOGIN_SUCCESS,
        payload: Object.assign({}, response)
    });

    const bookmakers: BookmakerData = yield call(GetBookmakersCall);
    yield put({
        type: SET_BOOKMAKERS,
        payload: bookmakers
    });

    yield put({
        type: SET_SETTINGS_AVERAGE_BOOKMAKERS,
        payload: response.averages.filter(b => ENABLED_BOOKMAKERS.has(b))
    });

    const moneyLines: MoneyLineObject = yield call(GetEventsMoneyLineCall);
    yield put({
        type: GET_EVENT_MONEY_LINE_SUCCESS,
        payload: moneyLines
    });

    const marketCap: MarketCapObject = yield call(GetMarketCapCall);
    yield put({
        type: GET_MARKET_CAP_SUCCESS,
        payload: marketCap
    });
}

export function* loginSaga({payload}: { payload: LoginPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: LOGIN_FAILED
    };
    yield call(skeletonSaga, sagaPayload);
}

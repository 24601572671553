import {skeletonGet} from './skeleton/skeletonGet';
import {HotMatchSettingsObject} from "../@types/response";

export const GetHotMatchesCall = (payload: HotMatchSettingsObject): Promise<any> => {
    const filters = new URLSearchParams({
        date: payload.date.toString(),
        odd_from: payload.from.toString(),
        odd_to: payload.to.toString(),
        delta: payload.delta.toString(),
        marketTv: payload.marketTv.toString()
    }).toString()
    return skeletonGet(`hot-match?${filters}`, 'hm');
};
import {MapPlayersPayload, SearchEventPayload} from '../@types/actions';
import {skeletonGet} from './skeleton/skeletonGet';
import {skeletonPost} from './skeleton/skeletonPost';

export const GetPlayersCall = (): Promise<any> => {
    return skeletonGet('player', 'player');
};

export const GetEventPlayersCall = (eventId: number): Promise<any> => {
    return skeletonGet(`player/${eventId}/players`, 'player');
};

export const GetEventPlayerCall = (eventId: number, playerId: string): Promise<any> => {
    return skeletonGet(`player/${eventId}/players/${playerId}`, 'player');
};

export const BlacklistPlayerCall = (eventId: number, playerId: string, bookmakerId: number): Promise<any> => {
    return skeletonPost({bookmakerId}, `player/${eventId}/players/${playerId}/blacklist`, 'player');
};

export const MapPlayersCall = (eventId: number, payload: MapPlayersPayload[]): Promise<any> => {
    return skeletonPost(payload, `player/${eventId}/whitelist`, 'player');
};

export const GetSettingsCall = (template: string = 'default'): Promise<any> => {
    return skeletonGet(`player/template/${template}`, 'player');
};

export const SearchPlayerEventCall = (payload: SearchEventPayload): Promise<any> => {
    return skeletonGet(`player/search/${payload.event}`, 'player');
};
import {FC, useContext, useEffect, useState} from "react";
import {BookmakerData, MarketData, MarketObject} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {getMarkets} from "../../../store/selectors/getMarkets";
import {getEventBookmakers} from "../../../store/selectors/getEventBookmakers";
import {getBetfairUrl} from '../../../store/utils/events'
import {BETFAIR, PINNACLE} from "../../../constants/CommonConstants";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {ModalsContext} from "../modals/ModalsContext";
import {EventModalProps} from "../../../@types/inputs";

interface OddsTableProps {
    odds: any;
    sport: number;
    event: EventModalProps | null;
}

export const OddsTable: FC<OddsTableProps> = ({sport, odds, event = null}) => {
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const allMarkets: MarketData = useSelector(getMarkets);
    const eventBookmakers: number[] = useSelector(getEventBookmakers);

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);
    const [bestBookmakers, setBestBookmakers]: any = useState({});
    const [markets, setMarkets] = useState<MarketObject[]>([]);
    useEffect(() => {
        const marketSigns = Object.keys(allMarkets).filter((marketId) => {
            return allMarkets[marketId].sport === sport
        }).map(marketId => {
            return allMarkets[marketId]
        })
        setMarkets(marketSigns)
    }, [])

    useEffect(() => {
        if (!markets.length || !Object.keys(odds).length) {
            setBestBookmakers({})
            return
        }

        let bests: any = {}
        for (const market of markets) {
            bests[market.id.toString()] = {}
            for (const signId in market.outcomes) {
                let max = 0
                for (const bookmakerId in bookmakers) {
                    if (bookmakers[bookmakerId].isComparison || bookmakers[bookmakerId].details < 0) continue
                    const backOdd = odds[bookmakerId]?.['markets']?.[market.id]?.['sign']?.[signId]?.['backOdd'] || 0
                    if (backOdd > max) max = backOdd
                }
                bests[market.id.toString()][signId] = max
            }
        }
        setBestBookmakers(bests)

    }, [markets, odds])

    return <table className='mt-4 w-full border-spacing-0'>
        <thead>
        <tr>
            <th className='text-left sticky left-0 z-10 bg-eventDetail-primary'/>
            {markets.map(item => {
                return <>
                    <th key={`market-${item.id}`} colSpan={Object.values(item.outcomes).length}
                        className={'font-extralight text-sm text-center'}>
                        {item.name}
                    </th>
                    <th/>
                </>
            })}
        </tr>
        </thead>
        <tbody>
        <tr>
            <th className='text-left sticky left-0 z-10 bg-eventDetail-primary'/>
            {markets.map(item => {
                return <>
                    {Object.keys(item.outcomes).map((signId) => <th
                            key={`sign-${signId}`}
                            className='font-extralight text-center'
                    >{item.outcomes[signId].name}</th>)}
                    <th className='w-4 block'/>
                </>
            })}
        </tr>
        {eventBookmakers.map((order_id) => {
            if (!Object.keys(bookmakers).includes(order_id.toString())) return null
            const bookmaker = bookmakers[order_id]
            const bgBook = bookmaker.id === BETFAIR ? 'bg-eventDetail-betfair' : (bookmaker.id === PINNACLE ? 'bg-eventDetail-pinnacle' : 'bg-eventDetail-bookmaker')
            return <tr key={`book-${bookmaker.id}`}
                       className={`${bookmaker.details > 0 ? 'border' : 'border-b'} border-monitor-primary ${bgBook}`}>
                <th className={`text-left sticky left-0 z-10 ${bgBook} border-eventDetail-primary`}>
                    {bookmaker.name}
                </th>
                {markets.map(item => {
                    const market = odds[bookmaker.id]?.markets[item.id.toString()]
                    return <>
                        {Object.keys(item.outcomes).map((signId) => {
                            const backOdd = market?.sign[signId]?.backOdd
                            const top = bestBookmakers[item.id.toString()]?.[signId] ?? 0
                            const betfairUrl = getBetfairUrl(
                                    odds[BETFAIR]?.markets[item.id]?.betfairMarketId,
                                    odds[BETFAIR]?.markets[item.id]?.sign[signId]?.betfairSelectionId
                            )
                            return <td
                                    className={`text-center px-1.5 ${!bookmaker.isComparison && !item.hasSbvs && backOdd >= top && 'outline outline-1 -outline-offset-2 outline-eventDetail-secondary bg-eventDetail-top'}`}>
                                {market?.main ? <i className='font-thin' style={{color: '#8f8476'}}>[{market.main}]&nbsp;</i> : null}
                                <b className={bookmaker.id === BETFAIR || (bookmaker.id === PINNACLE && event) ? 'cursor-pointer' : ''}
                                   onClick={bookmaker.id === BETFAIR ? () => {
                                       betfairUrl && window.open(betfairUrl, "betfair", "width=925,height=660")
                                   } : (bookmaker.id === PINNACLE && event) ? () => {
                                       setModalsDataCallback(MODALS_TYPES.SIGN_CHART_MODAL, {
                                           odd: {
                                               eventId: event.id,
                                               bookmakerId: PINNACLE,
                                               marketId: item.id.toString(),
                                               spread: '#', //TODO
                                               signId: signId,
                                           },
                                           event: {
                                               name: event.name,
                                               id: event.id
                                           }
                                       });
                                       setOpenedModalsCallback(MODALS_TYPES.SIGN_CHART_MODAL, true);
                                   } : () => {
                                   }}>{backOdd > 1 ? backOdd : '-'}</b>
                            </td>
                        })}
                        <td/>
                    </>
                })}
            </tr>
        })}
        </tbody>
    </table>
}

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import MonitorTable from "../layouts/components/monitor";
import Toolbar from "../layouts/components/monitor/Toolbar";
import MonitorItem from "../layouts/components/monitor/MonitorItem";
import {AlertMonitorObject, BookmakerData, EventsStoredObjects, User,} from "../@types/response";
import {getBookmakers} from "../store/selectors/getBookmakers";
import EventDetailsModal from "../layouts/components/modals/EventDetailsModal";
import {getMonitors} from "../store/selectors/monitor/getMonitors";
import {GetInitAction} from "../store/actions/monitor/GetInitAction";
import BookEventModal from "../layouts/components/modals/BookEventModal";
import getClient from "../store/selectors/getClient";
import SignChartModal from "../layouts/components/modals/SignChartModal";
import HideAlertModal from "../layouts/components/modals/HideAlertModal";
import {getEvents} from "../store/selectors/monitor/getEvents";
import MarketPlayerModal from "../layouts/components/modals/MarketPlayerModal";

const Monitor: React.FC = () => {
    const dispatch = useDispatch();

    const events: EventsStoredObjects = useSelector(getEvents);
    const monitors: AlertMonitorObject[] = useSelector(getMonitors);
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const client: undefined | User = useSelector(getClient);

    useEffect(() => {
        dispatch(GetInitAction(client?.id || ""));
        document.title = "MONITOR - OddMonitor";
    }, []);

    if (!Object.keys(bookmakers).length) {
        return (
                <div>
                    <div
                            id="toolbar-monitor"
                            className="bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10"
                    >
                        <Toolbar/>
                    </div>
                    <div className="mt-16">
                        <MonitorTable/>
                    </div>
                    <div>Loading</div>
                </div>
        );
    }

    return (
            <div>
                <div
                        id="toolbar-monitor"
                        className="bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10"
                >
                    <Toolbar/>
                </div>
                <div className="mt-16 mx-4">
                    <MonitorTable>
                        {monitors
                                //TODO: move this check in utils?
                                .filter(alert => client?.client == null || client.client === alert.bookmakerId)
                                .map((alert: AlertMonitorObject, idx: number) => (
                                        <MonitorItem alert={alert} key={`${alert.id}x${idx}`} event={events[alert.id]}/>
                                ))}
                    </MonitorTable>
                </div>

                <EventDetailsModal/>
                <MarketPlayerModal/>
                <BookEventModal/>
                <SignChartModal/>
                <HideAlertModal/>
            </div>
    );
};

export default Monitor;

import {skeletonGet} from './skeleton/skeletonGet';
import {skeletonPost} from "./skeleton/skeletonPost";
import {DeleteAlertMarketPayload, HideAlertPayload, UserExtraPayload} from "../@types/actions";
import {skeletonDelete} from "./skeleton/skeletonDelete";

export const GetExtraAlertsCall = (): Promise<any> => {
    return skeletonGet('extra', 'extra');
};

export const DeleteAlertCall = (payload: DeleteAlertMarketPayload): Promise<any> => {
    return skeletonDelete(`${payload.eventId}/${payload.bookmakerId}/${payload.marketId}`, 'event');
};

export const HideAlertCall = (payload: HideAlertPayload): Promise<any> => {
    return skeletonPost(payload, payload.eventId.toString(), 'extra/hide');
};

export const GetEventsMoneyLineCall = (): Promise<any> => {
    return skeletonGet('moneyline', 'events');
};

export const GetSettingsCall = (template: string = 'default'): Promise<any> => {
    return skeletonGet(template, 'extra/template');
};

export const StoreSettingCall = (payload: UserExtraPayload): Promise<any> => {
    return skeletonPost(payload, payload.name, 'extra/template');
};

export const DeleteSettingCall = (template: string): Promise<any> => {
    return skeletonDelete(template, 'extra/template');
};

export const GetSportsCall = (): Promise<any> => {
    return skeletonGet('main', 'extra');
};

export const GetMarketCapCall = (): Promise<any> => {
    return skeletonGet('market', 'events');
};
import {HotMatchObject, MarketData,} from "../../../@types/response";
import {useSelector} from "react-redux";
import {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartArea, faSearch,} from "@fortawesome/free-solid-svg-icons";
import {getMarkets} from "../../../store/selectors/getMarkets";
import Tooltip from "../common/Tooltip";
import {toMoney} from "../../../store/utils/commonUtils";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {ModalsContext} from "../modals/ModalsContext";
import {getEventFromHotMatch} from "../../../store/utils/hotMatchUtils";
import {getBetfairChart, getBetfairUrl} from "../../../store/utils/events";

export default function HotMatchItem({hotMatch}: { hotMatch: HotMatchObject }) {
    const markets: MarketData = useSelector(getMarkets);

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);
    const [showSelectionChart, setShowSelectionChart] = useState<boolean>(false);

    const startDt = new Date(hotMatch.dt);

    const key = `${hotMatch.eid}-${hotMatch.mk}-${hotMatch.sg}`
    const betfairChartUrl = hotMatch.bmkid && hotMatch.bsgid && getBetfairChart(hotMatch.bmkid, hotMatch.bsgid)
    const hasBetfair = betfairChartUrl != null;
    const label = `${hotMatch.c} - ${hotMatch.t}`;

    return <tr
            id={`hot-match-${key}`}
            key={`hot-match-${key}`}
            className={'border-b-2 tracking-[1px] border-black hover:bg-gray-600 bg-table-secondary'}
    >
        {/*event_name*/}
        <td className="items-center relative pl-6">
            <Tooltip isEvent={true} tooltipText={`${hotMatch.n}`}>
                <div className="flex flex-col py-1 2xl:max-w-[30rem] max-w-xs">
                    <div
                            className="text-left truncate 2xl:text-monitorBase text-[0.68rem] font-medium "
                            data-id="hotMatch-name"
                            data-value={hotMatch.n}
                    >
                        {hotMatch.n}
                    </div>
                    <div
                            className="text-left 2xl:text-monitorBase truncate text-[0.68rem]"
                            data-id="competition"
                            data-value={label.substring(0, 32)}
                    >
                        {label.substring(0, 32)}
                    </div>
                </div>
            </Tooltip>
        </td>
        {/*search*/}
        <td>
        <span
                className="cursor-pointer"
                onClick={() => {
                    if (hotMatch.pid) {
                        setModalsDataCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, {
                            event: getEventFromHotMatch(hotMatch),
                            playerId: hotMatch.pid,
                            marketId: hotMatch.mk
                        });
                        setOpenedModalsCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, true);
                    } else {
                        setModalsDataCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, {
                            event: {
                                id: hotMatch.eid,
                                name: hotMatch.n,
                                sportId: hotMatch.sid
                            }
                        });
                        setOpenedModalsCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, true);
                    }
                }}
        >
                    <FontAwesomeIcon icon={faSearch}/>
                    </span>
        </td>
        {/*date*/}
        <td
                className="py-1 px-3 text-center whitespace-nowrap"
                data-id="hotMatch-time"
                data-value={startDt.getTime()}
        >
            <dt className="sr-only">Time</dt>
            <dd>
                {[
                    startDt.getDate().toString().padStart(2, "0"),
                    (startDt.getMonth() + 1).toString().padStart(2, "0"),
                ].join("/")}
                &nbsp;&nbsp;
                {`${startDt.getHours().toString().padStart(2, "0")}:${startDt
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}`}
            </dd>
        </td>
        {/*delta*/}
        <td
                className="py-1 px-2 text-xs whitespace-nowrap font-bold text-end bg-margin-l0 text-table-margin"
                data-id="margin"
                data-value={hotMatch.v.toFixed(1) || "-"}
        >
            <dt className="sr-only">M</dt>
            <dd>{hotMatch.v.toFixed(1) || "-"}</dd>
        </td>
        {/*market*/}
        <td className="py-1 px-3 text-center whitespace-nowrap">
            <dt className="sr-only">Market</dt>
            <dd>
                {markets[hotMatch.mk].name}
            </dd>
        </td>
        {/*sign*/}
        <td className="py-1 px-3 text-center whitespace-nowrap">
            <dt className="sr-only">Sign</dt>
            {markets[hotMatch.mk].outcomes[hotMatch.sg].name}
        </td>
        {/*odd*/}
        <td
                className="px-2 text-center whitespace-nowrap relative btn-on-over"
                onClick={() => {
                    const betfairUrl = getBetfairUrl(hotMatch.bmkid, hotMatch.bsgid)
                    betfairUrl && window.open(betfairUrl, "betfair", "width=925,height=660")
                }}
        >
            <dt className="sr-only">Odd</dt>
            {hotMatch.o}
        </td>
        {/*betfair cap*/}
        <td className="text-right 2xl:text-xs text-[.6rem] 2xl:w-24 w-20">
            <div>{toMoney(hotMatch.mkt)}</div>
            <div>{`[${toMoney(hotMatch.sgt)}]`}</div>
        </td>
        {/*actions*/}
        <td className={`cursor-pointer text-center ml-2 ${hasBetfair}`}>
            <FontAwesomeIcon
                    className={hasBetfair ? "" : "text-gray-400"}
                    icon={faChartArea}
                    onMouseEnter={() => hasBetfair && setShowSelectionChart(true)}
                    onMouseLeave={() => hasBetfair && setShowSelectionChart(false)}
            />
            {showSelectionChart && hasBetfair && <img
                    className="absolute"
                    alt="MarketCap"
                    style={{zIndex: 9, left: 0, right: 0, top: 0, bottom: 0, margin: "auto"}}
                    src={betfairChartUrl}
            />}
        </td>
    </tr>;
}

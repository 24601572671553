import {call, put} from 'redux-saga/effects';
import {GET_BOOKS_ERROR, GET_BOOKS_SUCCESS} from '../../constants/UserConstants';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetGroupBooksCall} from "../../apis/user";
import {BooksObject} from "../../@types/response";

function* tryFunction() {
    const books: BooksObject[] = yield call(GetGroupBooksCall)
    yield put({
        type: GET_BOOKS_SUCCESS,
        payload: books
    });
}

export function* getGroupBooksSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GET_BOOKS_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}

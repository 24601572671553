import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HotMatchSettingsObject,} from "../../../@types/response";
import {useDispatch, useSelector} from "react-redux";
import FilterInput from "../common/FilterInput";
import getErrorMessage from "../../../store/selectors/getErrorMessage";
import {faRetweet} from "@fortawesome/free-solid-svg-icons";
import Logo from "../logo";
import Separator from "../Separator";
import {getSettings} from "../../../store/selectors/hotmatch/getSettings";
import {HeartBeatDropDown} from "../common/HeartBeatDropDown";
import {isLoading} from "../../../store/selectors/hotmatch/isLoading";
import {GetHotMatchesAction} from "../../../store/actions/hotmatch/GetHotMatchesAction";

export default function Toolbar() {

    const errorMessage: string = useSelector(getErrorMessage);
    const storedSettings: HotMatchSettingsObject = useSelector(getSettings);
    const loading: boolean = useSelector(isLoading);

    const [pendingSettings, setPendingSettings] = useState<boolean>(false);
    const [settings, setSetting] = useState<HotMatchSettingsObject>(storedSettings);

    const dispatch = useDispatch();

    useEffect(() => {
        setSetting(storedSettings);
    }, [storedSettings]);

    useEffect(() => {
        setPendingSettings(JSON.stringify(settings) !== JSON.stringify(storedSettings));
    }, [settings, storedSettings]);

    return (
            <div className="flex w-full h-14 2xl:text-monitorBase text-monitorSm">
                <Logo/>
                <ul className="flex items-center justify-around py-2 w-full">
                    <Separator/>
                    <li className="mx-4">
                        <div className="relative flex flex-col ml-4">
                            <label htmlFor="dataFilter" className={"text-white"}>
                                DATE
                            </label>
                            <select
                                    id="dataFilter"
                                    name="dataFilter"
                                    className={"text-white bg-toolbar-bgMain"}
                                    onChange={({target}) =>
                                            setSetting(Object.assign({}, settings, {date: parseInt(target.value)}))
                                    }
                                    value={settings.date}
                            >
                                <option value={0}>All</option>
                                <option value={1}>Today</option>
                                <option value={2}>Today + 1</option>
                                <option value={3}>Today + 2</option>
                                <option value={4}>Today + 3</option>
                                <option value={5}>Today + 4</option>
                                <option value={6}>Today + 5</option>
                                <option value={7}>Today + 6</option>
                            </select>
                        </div>
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="from"
                                name="from"
                                label="FROM"
                                min={1.1}
                                max={4}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setSetting(Object.assign({}, settings, {from: parseFloat(e.target.value)}));
                                }}
                                value={settings.from}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="to"
                                name="to"
                                label="TO"
                                min={0}
                                max={50}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setSetting(Object.assign({}, settings, {to: parseFloat(e.target.value)}));
                                }}
                                value={settings.to}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="delta"
                                name="delta"
                                label="DELTA"
                                min={20}
                                max={50}
                                step={1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setSetting(Object.assign({}, settings, {delta: parseFloat(e.target.value)}));
                                }}
                                value={settings.delta}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="marketCap"
                                name="marketCap"
                                label="MIN MARKET"
                                min={100}
                                step={50}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setSetting(Object.assign({}, settings, {marketTv: parseFloat(e.target.value)}));
                                }}
                                value={settings.marketTv}
                        />
                    </li>
                    <Separator/>
                    <li>
                        <button
                                className={`p-3 rounded-md ${
                                        loading
                                                ? "bg-toolbar-settingsLoading text-white "
                                                : pendingSettings
                                                        ? "bg-toolbar-settingsWarning text-black "
                                                        : "bg-toolbar-settings text-white "
                                }`}
                                onClick={() => {
                                    dispatch(GetHotMatchesAction(settings));
                                }}
                        >
                            <FontAwesomeIcon className={loading ? "animate-spin" : ""} icon={faRetweet}/>
                        </button>
                    </li>
                    <li className="mr-4">
                        <HeartBeatDropDown/>
                    </li>
                </ul>
                <div>{errorMessage}</div>
            </div>
    );
}

export enum MODALS_TYPES {
    CONTINUE_MODAL,
    EVENT_DETAILS_MODAL,
    PLAYER_DETAILS_MODAL,
    PLAYER_MARKET_MODAL,
    BOOK_EVENT_MODAL,
    BOOK_PLAYER_MODAL,
    TEMPLATE_MODAL,
    SIGN_CHART_MODAL,
    HIDE_ALERT_MODAL,
    HIDE_PLAYER_MODAL
}